import Link from 'next/link';
import { Col, Container, Row } from 'react-bootstrap';

import { ImageLoader } from '@components';
import { Pencil2Svg } from '@components/Icon/Pencil2Svg';
import { Star2Svg } from '@components/Icon/Star2Svg';

export const JoinUs = () => {
  return (
    <Container className="my-5">
      <div className="rounded" style={{ background: '#FBD4D0' }}>
        <Row className="p-3 p-sm-5 align-items-center justify-content-center position-relative">
          <Col xs={12} md={4} lg={5}>
            <div className="position-relative">
              <ImageLoader src="/images/join-us.png" width={785} height={947} alt="Join Us" />
            </div>
          </Col>
          <Col xs={12} md={{ span: 7, offset: 1 }} lg={{ span: 6, offset: 1 }}>
            <div className="mt-5 mt-md-0 mb-2 text-center text-md-start ms-md-n4 ms-xl-0">
              <div className="text-uppercase fs-sm">Are you an expert?</div>
              <div>
                <div className="d-inline-block position-relative display-3 font-title py-4">
                  <Pencil2Svg
                    fill="#B89AE5"
                    style={{ position: 'absolute', top: 45, right: -35 }}
                    className="d-none d-md-block"
                  />
                  <Pencil2Svg
                    fill="#B89AE5"
                    style={{ position: 'absolute', bottom: -45, right: -60 }}
                    className="d-md-none"
                  />
                  <div className="d-md-none display-1 ">
                    Sign Up Now To
                    <br /> Meet New
                    <br /> Customers And
                    <br /> Build Community.
                    <br /> For Free!
                  </div>
                  <div className="d-none d-md-block">
                    Sign Up Now To Meet New
                    <br /> Customers And Build
                    <br /> Community. For Free!
                  </div>
                </div>
              </div>

              <div className="mt-2 mt-md-4 position-relative d-inline-block">
                <Star2Svg
                  fill="#C8F954"
                  style={{ position: 'absolute', top: '-40px', left: '-60px', zIndex: 0 }}
                />

                <div className="d-flex flex-column flex-md-row justify-content-around justify-content-md-start align-items-center gap-3 position-relative mx-n2 mx-md-0 z-1">
                  <Link
                    target="_blank"
                    href="https://www.westtenth.com/sellwithus"
                    className="btn btn-primary text-uppercase me-md-3 me-lg-4 me-xl-5 me-xxl-6"
                  >
                    Start Selling
                  </Link>
                  <Link
                    target="_blank"
                    href="https://www.westtenth.com/sellwithus"
                    className="text-uppercase"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>

            <div className="position-absolute bottom-0 end-0 pe-5 d-none d-md-block">
              <ImageLoader
                src="/images/locally-grown.png"
                width={100}
                height={100}
                alt="Locally Grown"
                style={{ marginBottom: '-10px' }}
                className="mb-n5"
              />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
