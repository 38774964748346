import { useRouter } from 'next/router';
import PubSub from 'pubsub-js';
import { useState } from 'react';
import { Button, ButtonGroup, Col, Container, Form, Row, ToggleButton } from 'react-bootstrap';
import { useRefinementList } from 'react-instantsearch';

import { ConfigureSearch, ImageLoader, PlaceSearch } from '@components';
import { Star1Svg } from '@components/Icon/Star1Svg';
import { useSelectedDistance } from '@hooks';
import { EventName } from '@packages/event-tracking';
import { categoryFilterSerializer } from '@screens/CategoryScreen';
import { useAvailableDistances } from '@screens/CategoryScreen/components/DistanceFilterItem';

export const SearchNearBy = () => {
  const router = useRouter();

  const [searchTerm, setSearchTerm] = useState('');
  const { distance: selectedDistance, setDistance } = useSelectedDistance();

  const availableDistances = useAvailableDistances();

  const deliveryRefinements = useRefinementList({
    attribute: 'fulfillmentOptions',
    sortBy: ['name:desc'],
  });

  const handleSearch = () => {
    // PubSub.publish(EventName.SearchStores, val);

    router.push(
      `/search/${categoryFilterSerializer.serialize({
        query: [searchTerm],
        fulfillments: deliveryRefinements.items
          .filter((fulfillment) => fulfillment.isRefined)
          .map((fulfillment) => fulfillment.value),
        distance: [selectedDistance],
      })}`
    );
  };

  return (
    <Container className="my-3">
      <Row>
        <Col className="d-none d-lg-block">
          <div className="position-relative rounded bg-periwinkle p-5 h-100 d-flex justify-content-center align-items-center">
            <ImageLoader
              src="/images/search-near-by.png"
              width={500}
              height={500}
              style={{ width: '100%', height: 'auto' }}
              alt="Search Near By"
            />
          </div>
        </Col>
        <Col>
          <ConfigureSearch />

          <div className="position-relative rounded bg-almond p-4 p-xl-5 h-100">
            <div className="display-2 font-title">
              By Expert{' '}
              <span className="mark-highlight">
                <span>Neighbors</span>
              </span>
              <br /> In Your Community
            </div>

            <div className="mt-3">
              <div className="text-uppercase text-primary fs-6 mb-2">Search</div>
              <Form.Control
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="FIND SERVICES AND GOODS..."
                className="bg-almond border-primary"
              />
            </div>
            <div className="mt-3">
              <div className="text-uppercase text-primary fs-6 mb-2">Location</div>
              <PlaceSearch isFrontPage={true} />
            </div>
            <div className="mt-3">
              <div className="text-uppercase text-primary fs-6 mb-2">Delivery Options</div>
              <Row>
                {deliveryRefinements.items.map(({ value, label, isRefined, count }, indx) => (
                  <Col xs={12} sm={6} md={4} lg={6} xxl={4} key={indx}>
                    <Form.Check
                      id={`type-${indx}`}
                      value={value}
                      checked={isRefined}
                      onChange={() => deliveryRefinements.refine(value)}
                      label={<span className="fs-sm text-uppercase">{label}</span>}
                    />
                  </Col>
                ))}
              </Row>
            </div>
            <div className="mt-3">
              <div className="text-uppercase text-primary fs-6 mb-2">Distance</div>
              <div className="text-uppercase text-primary fs-xs mb-2">Within (in miles)</div>
              <div className="d-flex flex-wrap column-gap-4">
                <ButtonGroup>
                  {availableDistances.map((distance, indx) => (
                    <ToggleButton
                      key={indx}
                      className="btn-xs p-2"
                      type="radio"
                      id={`distance-${indx}`}
                      checked={selectedDistance === distance}
                      name="distance"
                      value={distance}
                      onChange={() => setDistance(distance)}
                      variant="outline-primary"
                      style={{ minWidth: '42px' }}
                    >
                      {distance}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </div>
            </div>

            <div className="d-flex justify-content-lg-end mt-3">
              <Button onClick={handleSearch} className="text-uppercase fs-sm">
                Browse Shops
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
