import { CSSProperties } from 'react';

export function Star2Svg({
  fill = '#3C5045',
  ...props
}: {
  fill?: string;
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <div {...props}>
      <svg
        width="131"
        height="93"
        viewBox="0 0 131 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58.8531 30.5338L71.6807 0L71.8987 37.2374L107.736 18.5252L83.0131 45.9363L131 75.7166L77.1169 54.0023L69.1382 93L67.2192 50.6204L38.5433 87.0922L60.2334 48.5527L0 51.4945L48.6831 40.2395L44.4213 10.3146L58.8531 30.5338Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
