import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

import { ImageLoader, PlaceSearch } from '@components';
import { Pencil2Svg } from '@components/Icon/Pencil2Svg';
import { Star1Svg } from '@components/Icon/Star1Svg';
import { Star2Svg } from '@components/Icon/Star2Svg';
import { useUserLocation } from '@hooks';
import { useIsMediaBreakpoint } from '@hooks/useIsMediaBreakpoint';
import { useSubscribeNewsletter } from '@hooks/useSubscribeNewsletter/useSubscribeNewsletter';

export const AdvancedNewsletter = () => {
  const isMobile = useIsMediaBreakpoint('sm');
  const { location } = useUserLocation();
  const [email, setEmail] = useState('');
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [sms, setSms] = useState('');
  const [isSmsChecked, setIsSmsChecked] = useState(false);
  const [error, setError] = useState('');

  const { mutateAsync: subscribeNewsletter, isLoading, isSuccess } = useSubscribeNewsletter();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    const formattedSms = sms ? `+1${sms.replace(/\D/g, '').slice(-10)}` : '';
    if (sms && formattedSms.length !== 12) {
      setError('Please Enter Correct SMS Number.');
      return;
    }

    let city = '';
    let state = '';
    if (location) {
      const address = (await geocodeByPlaceId(location.place_id))?.[0].address_components;
      for (const component of address) {
        switch (component.types[0]) {
          case 'locality':
            city = component.long_name;
            break;

          case 'administrative_area_level_1': {
            state = component.short_name;
            break;
          }
        }
      }
    }

    await subscribeNewsletter({ email, sms: formattedSms, city, state });
  };

  return (
    <Container className="my-3">
      <Row className="gy-2">
        <Col className="d-none d-lg-block overflow-hidden">
          <div
            className="position-relative rounded p-2 h-100 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: '#DDFF7B' }}
          >
            <ImageLoader
              src="/images/newsletter.png"
              width={500}
              height={500}
              style={{ maxWidth: 500 }}
              alt="Search Near By"
            />
          </div>
        </Col>
        <Col xs={12} lg={6}>
          {isSuccess ? (
            <div className="position-relative rounded bg-almond px-2 py-6 p-xl-5 h-100 d-flex flex-column justify-content-center align-items-center text-center">
              <Star1Svg
                fill="#B5F91E"
                style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }}
              />
              <Star2Svg
                fill="#F4F3E3"
                style={{ position: 'absolute', bottom: 0, right: 0 }}
                className="d-none d-lg-block"
              />
              <Star2Svg
                fill="#F4F3E3"
                style={{ position: 'absolute', bottom: 10, right: -50 }}
                className="d-lg-none"
              />

              <div className="display-2 font-title z-1 position-relative">
                Welcome To Our
                <br />
                Community!
              </div>
              <div className="my-4">
                <div className="d-md-none" style={{ fontSize: '1.4rem', lineHeight: 1.1 }}>
                  Keep an eye on your inbox
                  <br /> for exclusive news,
                  <br /> promotions, and business
                  <br /> features!
                </div>
                <div className="d-none d-md-block" style={{ fontSize: '2rem', lineHeight: 1.1 }}>
                  Keep an eye on your inbox for
                  <br /> exclusive news, promotions, and
                  <br /> business features!
                </div>
              </div>
              <div className="position-relative px-3">
                <ImageLoader
                  src="/images/newsletter-success.png"
                  width={250}
                  height={200}
                  alt="Newsletter Success"
                />
              </div>
            </div>
          ) : (
            <div
              className="position-relative rounded p-4 p-xl-5 h-100"
              style={{ backgroundColor: '#C3CFF6' }}
            >
              <Pencil2Svg
                fill="#B89AE5"
                style={{ position: 'absolute', top: '50px', right: '20px' }}
                className="d-none d-md-block"
              />

              <div className="text-center text-uppercase mb-3 d-lg-none fs-xs">
                We&rsquo;re Growing
              </div>
              <div className="display-3 font-title text-center text-lg-start">
                Want West Tenth To
                <br /> Come Into{' '}
                <span className="mark-highlight">
                  <span>Your City?</span>
                </span>
              </div>
              <div className={`mt-2 text-center text-lg-start ${isMobile ? 'fs-lg' : 'fs-sm'}`}>
                Share Your Location & Never Miss An
                <br /> Exclusive or New Arrival Near You!
              </div>
              <Form onSubmit={handleSubmit}>
                <div className="mt-3">
                  <div className="text-uppercase text-primary mb-1">Location</div>
                  <PlaceSearch size={'sm'} isFrontPage={true} />
                </div>
                <div className="mt-3">
                  <div className="text-uppercase text-primary mb-1">Email</div>
                  <Form.Control
                    size="sm"
                    required
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="ENTER EMAIL ADDRESS"
                    className="border-primary"
                    style={{ backgroundColor: '#C3CFF6' }}
                  />
                  <Form.Check
                    required
                    checked={isEmailChecked}
                    onChange={(e) => setIsEmailChecked(e.target.checked)}
                    label={
                      <span className="fs-sm">
                        I would like to receive newsletters, promotions, and updates at the email
                        provided.
                      </span>
                    }
                    className="mt-1"
                  ></Form.Check>
                </div>
                {/*<div className="mt-3">*/}
                {/*  <div className="text-uppercase text-primary mb-1">SMS</div>*/}
                {/*  <Form.Control*/}
                {/*    type="tel"*/}
                {/*    onChange={(e) => setSms(e.target.value)}*/}
                {/*    value={sms}*/}
                {/*    placeholder="(221) - 873 - 9773"*/}
                {/*    className="border-primary"*/}
                {/*    style={{ backgroundColor: '#C3CFF6' }}*/}
                {/*  />*/}
                {/*  {error && <div className="text-danger mt-1">{error}</div>}*/}
                {/*  <Form.Check*/}
                {/*    required={!!sms}*/}
                {/*    checked={isSmsChecked}*/}
                {/*    onChange={(e) => setIsSmsChecked(e.target.checked)}*/}
                {/*    label={*/}
                {/*      <span className="fs-sm">*/}
                {/*        I would like to receive exclusive discounts, promotions, and updates at the*/}
                {/*        number provided. message and data rates may apply.*/}
                {/*      </span>*/}
                {/*    }*/}
                {/*    className="mt-1"*/}
                {/*  ></Form.Check>*/}
                {/*</div>*/}

                <div className="text-center text-lg-start">
                  <Button
                    type="submit"
                    size="sm"
                    className="rounded rounded-4 text-uppercase mt-3 px-4 py-2"
                  >
                    Subscribe
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
