import Link from 'next/link';
import { useRef } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { isSuperCategory, useSuperCategories } from '@api';
import { ImageLoader } from '@components';
import { Pencil1Svg } from '@components/Icon/Pencil1Svg';
import { seasonalCategoryId } from '@constants';
import { useIsMediaBreakpoint } from '@hooks/useIsMediaBreakpoint';

type TopDoersCategory = { url: string; title: string };

export const CategoryCarousel = () => {
  const ref = useRef<SwiperRef>(null);
  const isTablet = useIsMediaBreakpoint('md');

  const { data: superCategories } = useSuperCategories();

  const topDoersList = ['fresh', 'trending', 'seasonal', 'self-care', 'family', 'parties-events'];
  const topDoersCategories: TopDoersCategory[] = topDoersList
    .map((slug) => {
      if ('fresh' === slug) {
        return {
          title: "What's new",
          url: '/browse/category/fresh',
        };
      } else if ('seasonal' === slug) {
        const category = superCategories?.categoryTaxonomy.find(
          (cat) => cat.categoryId === seasonalCategoryId
        );
        if (category) {
          return {
            title: 'Seasonal',
            url: isSuperCategory(category)
              ? `browse/${category?.uriSlug}`
              : `browse/category/${category?.uriSlug}`,
          };
        } else {
          return;
        }
      } else {
        const category = superCategories?.categoryTaxonomy.find((cat) => cat.uriSlug === slug);
        if (category) {
          return {
            title: category?.title,
            url: isSuperCategory(category)
              ? `browse/${category?.uriSlug}`
              : `browse/category/${category?.uriSlug}`,
          };
        } else {
          return;
        }
      }
    })
    .filter((cat) => !!cat) as TopDoersCategory[];

  const renderSlogan = () => {
    return (
      <div className="category-carousel-slogan">
        <div className="position-relative d-inline-block">
          <Pencil1Svg
            style={{ position: 'absolute', bottom: 0, right: 20, width: '30%' }}
            className="d-none d-md-block"
          />
          A Marketplace for
          <br />
          <span className="mark-label">Local</span> Experts and
          <br />
          Original Ideas &mdash;
        </div>
      </div>
    );
  };

  const renderText = () => {
    return (
      <div className="category-carousel-text">
        <div className="font-serif mb-3 mt-md-3 text">
          From home bakers to party <br />
          planners, organizers, and more.
        </div>
        <div className="d-flex align-items-center mx-n1 mx-sm-0">
          <Link href="/search" className="btn btn-primary text-uppercase me-2 me-sm-5">
            Browse Shops
          </Link>
          <Link
            target="_blank"
            href="https://www.westtenth.com/sellwithus"
            className="text-uppercase"
          >
            Start Selling
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      <Container>
        <Row>
          <Col className="mt-4 d-md-none d-flex justify-content-center">{renderSlogan()}</Col>

          <Col>
            <div className="position-relative py-1 px-3 fancy-swiper">
              <Swiper
                loop={true}
                modules={[Navigation, Autoplay]}
                ref={ref}
                spaceBetween={0}
                shortSwipes={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                navigation={{
                  prevEl: '#prev-category',
                  nextEl: '#next-category',
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                  768: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                  },
                  1100: {
                    slidesPerView: 4,
                    slidesPerGroup: 1,
                  },
                }}
                noSwiping={true}
                noSwipingClass="yarl__slide"
              >
                {superCategories?.categoryTaxonomy?.map(
                  (category, idx) =>
                    isSuperCategory(category) && (
                      <SwiperSlide className="py-2 p-md-2 h-100" key={idx}>
                        <div className="text-center text-uppercase">
                          <Link
                            href={`/browse/${category.uriSlug}`}
                            passHref
                            style={{ textDecoration: 'none' }}
                          >
                            <div
                              className="d-flex justify-content-center align-items-center position-relative mb-4"
                              style={{
                                height: 200,
                                maxWidth: 350,
                              }}
                            >
                              <ImageLoader
                                src={category.image || ''}
                                fill
                                style={{ objectFit: 'cover', objectPosition: 'center' }}
                                alt={category.title}
                              />
                            </div>

                            {category.title}
                          </Link>
                        </div>
                      </SwiperSlide>
                    )
                )}
              </Swiper>
              <Button id="prev-category" variant="prev" />
              <Button id="next-category" variant="next" />
            </div>
          </Col>

          <Col className="d-md-none mb-3">{renderText()}</Col>
        </Row>
      </Container>

      <div className="border-primary border-top border-bottom">
        <Container>
          <Row>
            <Col
              xs={12}
              md={5}
              lg={4}
              xl={3}
              className="ps-md-3"
              style={{ ...(isTablet ? { maxWidth: 260 } : {}) }}
            >
              <Row>
                <Col className="font-serif fs-lg mt-3 mb-2">
                  <span className="mark-highlight mark-highlight-50">
                    <span>Top Doers</span>
                  </span>{' '}
                  in
                  <br />
                  Your Community
                </Col>
              </Row>
              <Row
                className="border-primary border-bottom d-none d-md-block mt-1"
                style={{ marginLeft: '-1000rem' }}
              ></Row>

              <div className="my-2 d-flex flex-row flex-md-column flex-wrap mx-n1">
                <div>
                  <Link href="/search" passHref style={{ textDecoration: 'none' }}>
                    <div
                      className="fs-xs text-uppercase border border-primary rounded-4 py-1 m-1 d-inline-block"
                      style={{ padding: '0 15px' }}
                    >
                      All
                    </div>
                  </Link>
                </div>

                {topDoersCategories?.map((category, idx) => (
                  <div key={idx}>
                    <Link href={category.url} passHref style={{ textDecoration: 'none' }}>
                      <div
                        className="fs-xs text-uppercase border border-primary rounded-4 py-1 m-1 d-inline-block"
                        style={{ padding: '0 15px' }}
                      >
                        {category.title}
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </Col>
            <Col
              md={7}
              lg={8}
              xl={9}
              className="p-3 pe-2 py-xxl-5 ps-xl-4 border-primary border-start d-none d-md-block"
            >
              <div className="d-flex flex-column flex-xxl-row align-items-xxl-end">
                {renderSlogan()}
                <div className="ms-xxl-4">{renderText()}</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
