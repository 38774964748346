import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from 'react-query';

import { getSuperCategories } from '@api/fetchSuperCategories';
import { ScreenName } from '@packages/event-tracking';
import { HomeScreen } from '@screens/HomeScreen';

type IndexPageProps = {
  screenName: ScreenName;
};

export const getStaticProps: GetStaticProps<IndexPageProps> = async () => {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery(['superCategories'], () => getSuperCategories());

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      screenName: ScreenName.Home,
    },
  };
};

export default HomeScreen;
