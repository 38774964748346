import Link from 'next/link';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { ImageLoader } from '@components';
import { Star1Svg } from '@components/Icon/Star1Svg';
import { Star2Svg } from '@components/Icon/Star2Svg';
import { useIsMediaBreakpoint } from '@hooks/useIsMediaBreakpoint';

export const CityList = () => {
  const isTablet = useIsMediaBreakpoint('md');

  return (
    <Container className="my-3">
      <Row className="gy-2">
        <Col xs={12} lg={6}>
          <div
            className="position-relative rounded p-4 p-xl-5 d-flex flex-column align-items-center justify-content-center text-center h-100"
            style={{ background: '#D4C3F5' }}
          >
            <div className="text-uppercase fs-xs">Cities We&rsquo;re In</div>
            <div
              className="my-3 my-lg-5 font-serif position-relative"
              style={{ fontSize: isTablet ? '2rem' : '1.2rem', lineHeight: 1.2 }}
            >
              <Star1Svg
                fill="#B5F91E"
                style={{ position: 'absolute', top: '-50px', left: '-50px', zIndex: 0 }}
                className="d-none d-md-block"
              />
              <Star2Svg
                fill="#F4F3E3"
                style={{ position: 'absolute', bottom: '-60px', right: '-20px', zIndex: 0 }}
                className="d-none d-md-block"
              />

              <div className="position-relative z-1">
                <div>Ventura County, CA</div>
                <div>Salt Lake & Provo, UT</div>
                <div>Tampa, FL</div>
                <div>Austin, TX</div>
                <div>San Antonio, TX</div>
                <div>Dallas-Fort Worth, TX</div>
              </div>
            </div>
            <div className="text-uppercase fs-xs">Cities We&rsquo;re In</div>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="position-relative rounded bg-almond p-4 p-xl-5 h-100">
            <Star1Svg
              fill="#DDFF7B"
              style={{ position: 'absolute', top: '-80px', right: '5px' }}
              className="d-md-none"
            />

            <div className="position-absolute top-0 end-0 p-2 d-none d-md-block">
              <ImageLoader
                src="/images/locally-grown.png"
                width={85}
                height={85}
                alt="Locally Grown"
              />
            </div>

            <div className="display-2 font-title text-center text-md-start">
              Put Your Money
              <br /> Where Your Heart Is
            </div>
            <div className="my-4">
              We&rsquo;re here to remind you that some of the most interesting, talented creators,
              makers, and entrepreneurs live right in our communities. We&rsquo;re here to celebrate
              human creativity and ingenuity. Unique personalities and the original ideas they bring
              to life. We&rsquo;re here for independent expertise, things made by hand, and
              customization as a means of connection. We are made by people and for people, always.
            </div>

            <div className="d-flex justify-content-center justify-content-md-start ">
              <Link href="/search" passHref className="position-relative">
                <ImageLoader
                  src="/images/btn-doodle-left.png"
                  width={30}
                  height={137}
                  alt=""
                  style={{ position: 'absolute', left: '-40px' }}
                  className="d-md-none"
                />
                <ImageLoader
                  src="/images/btn-doodle-right.png"
                  width={30}
                  height={137}
                  alt=""
                  style={{ position: 'absolute', right: '-40px' }}
                  className="d-md-none"
                />
                <Button className="text-uppercase">Browse Shops</Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
