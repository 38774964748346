import { CSSProperties } from 'react';

export function Star1Svg({
  fill = '#3C5045',
  ...props
}: {
  fill?: string;
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <div {...props}>
      <svg width="132" height="152" viewBox="0 0 132 152" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M56.4928 48.9461L65.8648 0L70.8012 59.7897L132 48.4459L78.7014 75.0747L94.2514 128.332L64.8836 79.5162L46.1294 152L51.0658 78.5258L0 83.9476L54.0196 69.3028L23.9108 41.5437L56.4928 48.9461Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
