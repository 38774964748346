import PubSub from 'pubsub-js';
import { useEffect, useRef } from 'react';

import { EventName, trackEvent, useGetBaseEventPayload } from '@packages/event-tracking';
import { fbq } from '@packages/fbpixel';
import { ga4 } from '@packages/ga4';

export const useTrackHomeScreenEvents = () => {
  const getBaseEventPayload = useGetBaseEventPayload();
  const isTracked = useRef(false);
  const baseEventPayload = getBaseEventPayload();

  useEffect(() => {
    (async () => {
      const baseEventPayload = await getBaseEventPayload();
      if (!isTracked.current && baseEventPayload) {
        trackEvent(EventName.HomeView, baseEventPayload);
        isTracked.current = true;
      }
    })();
  }, [getBaseEventPayload]);

  useEffect(() => {
    const searchStoresToken = PubSub.subscribe(EventName.SearchStores, async (_, searchTerm) => {
      const baseEventPayload = await getBaseEventPayload();
      if (baseEventPayload && searchTerm) {
        const payload = {
          'user behavior': {
            'search term': searchTerm,
          },
          ...baseEventPayload,
        };
        trackEvent(EventName.SearchStores, payload);
        fbq.searchEvent({ customProperties: payload, search_string: searchTerm });
        ga4.searchEvent({ customProperties: payload, search_string: searchTerm });
      }
    });

    return () => {
      PubSub.unsubscribe(searchStoresToken);
    };
  }, [baseEventPayload, getBaseEventPayload]);
};
