import { Container } from 'react-bootstrap';
import {
  InstantSearch,
  InstantSearchServerState,
  InstantSearchSSRProvider,
} from 'react-instantsearch';

import {
  Footer,
  HeaderWithSearch,
  seasonalCategory,
  StoresCarousel,
  trendingCategory,
  whatsNewCategory,
} from '@components';
import { CategoryCarousel } from '@components/CategoryCarousel/CategoryCarousel';
import { SidebarLayout } from '@components/SidebarLayout';
import { searchClient, searchIndex } from '@constants';
import { AdvancedNewsletter } from '@screens/HomeScreen/AdvancedNewsletter';
import { AiBotBanner } from '@screens/HomeScreen/AiBotBanner';
import { CityList } from '@screens/HomeScreen/CityList';
import { JoinUs } from '@screens/HomeScreen/JoinUs';
import { SearchNearBy } from '@screens/HomeScreen/SearchNearBy';

import { CompanyDescription } from './CompanyDescription';
import { ExploreTalents, HomeBannerWithoutImage, HomeScreenHead, Quotes } from './components';
import { BrowseNearby } from './components/BrowseNearby';
import { FeaturedIn } from './FeaturedIn';
import { ImpactSection } from './ImpactSection';
import { useTrackHomeScreenEvents } from './useTrackHomeScreenEvents';
// import { WeAreExpandingSection } from './WeAreExpandingSection';

export const HomeScreen = ({ serverState }: { serverState?: InstantSearchServerState }) => {
  useTrackHomeScreenEvents();
  return (
    <>
      <HomeScreenHead />

      <InstantSearchSSRProvider {...serverState}>
        <InstantSearch searchClient={searchClient} indexName={searchIndex}>
          <main className="page-wrapper">
            <SidebarLayout>
              <HeaderWithSearch shouldTrackEvent={false} />
              <section className="position-relative overflow-hidden">
                <CategoryCarousel />
                <div className="mx-md-2">
                  <SearchNearBy />

                  <Container>
                    <div className="my-md-6">
                      <StoresCarousel {...trendingCategory} isFrontPage={true} />
                    </div>
                  </Container>

                  {/*<AiBotBanner />*/}

                  <Container>
                    <div className="my-md-6">
                      <StoresCarousel {...seasonalCategory} isFrontPage={true} />
                    </div>
                  </Container>

                  <CityList />
                  <Quotes />
                  <AdvancedNewsletter />
                  <JoinUs />
                </div>
              </section>
              <Footer isDisplayDownloadApp={true} isBigLogo={true} />
            </SidebarLayout>
          </main>
        </InstantSearch>
      </InstantSearchSSRProvider>
    </>
  );
};
